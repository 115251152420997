<template>
  <div class='error-page'>
    <span class="error-tip">404</span>
    <div>
      <p>页面走丢了。。。</p>
      <router-link to="/">
        <el-button type="text">返回首页</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'error-401'
}
</script>

<style lang='scss' scoped>
.error-page {
  width: 100%;
  height: 100%;
  background-color: #172b53b8;
  text-align: center;
  .error-tip {
    display: inline-block;
    font-size: 200px;
    margin-top: 100px;
    color: #fff;
    letter-spacing: 20px;
  }
}
</style>
